/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 35,
    width: 23,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox='0 0 23 35' className={classes.root}>
      <path
        d='M0.125 3.5C0.125 1.567 1.692 0 3.625 0H19.375C21.308 0 22.875 1.567 22.875 3.5V29.75C22.875 31.2448 21.9379 32.5208 20.6191 33.0224L21.996 30.5797C22.2177 30.1863 22.3342 29.7424 22.3342 29.2908V26.1725C22.3342 25.2868 21.8876 24.4609 21.1464 23.9759L15.9081 20.5485C15.382 20.2043 14.7125 20.1655 14.1501 20.4467L14.1444 20.4491L14.1426 20.4496C14.1426 20.4496 14.1383 20.449 14.1332 20.4459C14.1311 20.4446 14.1282 20.442 14.1282 20.442C14.1265 20.4404 14.1257 20.4391 14.1257 20.4391L14.1253 20.4373L14.125 20.4312V18.5476C15.6942 17.6399 16.75 15.9432 16.75 14C16.75 11.1005 14.3995 8.75 11.5 8.75C8.6005 8.75 6.25 11.1005 6.25 14C6.25 15.9432 7.30577 17.6399 8.875 18.5476V24.1376L6.97145 22.234C6.08975 21.3523 4.66024 21.3523 3.77855 22.234C3.0393 22.9733 2.90427 24.1234 3.45219 25.0137L8.52067 33.25H3.625C1.692 33.25 0.125 31.683 0.125 29.75V3.5Z'
        fill='#E6EEF5'
      />
      <path
        d='M14.125 17.5003V14C14.125 12.5503 12.9497 11.375 11.5 11.375C10.0503 11.375 8.875 12.5503 8.875 14V17.5003C7.81236 16.7021 7.125 15.4313 7.125 14C7.125 11.5838 9.08375 9.625 11.5 9.625C13.9162 9.625 15.875 11.5838 15.875 14C15.875 15.4313 15.1876 16.7021 14.125 17.5003Z'
        fill='#E6EEF5'
      />
      <path
        d='M9.75 14C9.75 13.0335 10.5335 12.25 11.5 12.25C12.4665 12.25 13.25 13.0335 13.25 14V21.6223C13.25 21.7384 13.3722 21.8139 13.4761 21.762L14.5414 21.2293C14.8254 21.0873 15.1634 21.1069 15.429 21.2807L21.0067 24.9302C21.2891 25.1149 21.4592 25.4296 21.4592 25.767V29.4876C21.4592 29.6596 21.4148 29.8287 21.3304 29.9786L18.7869 34.491C18.6096 34.8055 18.2767 35 17.9157 35H10.7806C10.6839 35 10.5941 34.9498 10.5434 34.8674L4.19739 24.5551C3.86182 24.0098 3.94452 23.3055 4.39727 22.8527C4.93725 22.3127 5.81275 22.3127 6.35273 22.8527L9.66372 26.1637C9.69556 26.1956 9.75 26.173 9.75 26.128V14Z'
        fill='#E6EEF5'
      />
    </SvgIcon>
  );
};
