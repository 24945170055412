import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PointMapIcon = ({ className }) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" className={className}>
    <path d="M9.37498 18.5419V17.0996C7.63887 16.9212 6.17733 16.2385 4.99035 15.0516C3.8034 13.8646 3.12071 12.403 2.94229 10.6669H1.5V9.41697H2.94229C3.12071 7.68086 3.8034 6.21932 4.99035 5.03235C6.17733 3.84539 7.63887 3.1627 9.37498 2.98428V1.54199H10.6249V2.98428C12.361 3.1627 13.8226 3.84539 15.0096 5.03235C16.1965 6.21932 16.8792 7.68086 17.0576 9.41697H18.4999V10.6669H17.0576C16.8792 12.403 16.1965 13.8646 15.0096 15.0516C13.8226 16.2385 12.361 16.9212 10.6249 17.0996V18.5419H9.37498ZM9.99996 15.8753C11.6111 15.8753 12.9861 15.3058 14.125 14.1669C15.2638 13.0281 15.8333 11.6531 15.8333 10.0419C15.8333 8.43084 15.2638 7.05584 14.125 5.91695C12.9861 4.77806 11.6111 4.20862 9.99996 4.20862C8.38885 4.20862 7.01385 4.77806 5.87496 5.91695C4.73607 7.05584 4.16662 8.43084 4.16662 10.0419C4.16662 11.6531 4.73607 13.0281 5.87496 14.1669C7.01385 15.3058 8.38885 15.8753 9.99996 15.8753Z" fill="#333945"/>
  </SvgIcon>
);

export default PointMapIcon;
