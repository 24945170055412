/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function CategoriesIcon({ fill }) {
  return (
    <SvgIcon viewBox='0 0 20.635 17.1'>
      <path
        id='13487'
        data-name='13487'
        d='M-8.472-7.4h5.879A1.644,1.644,0,0,0-.747-9.287V-13.32a1.64,1.64,0,0,0-1.846-1.885H-8.472a1.64,1.64,0,0,0-1.846,1.885v4.033A1.644,1.644,0,0,0-8.472-7.4Zm11.064,0H8.472a1.644,1.644,0,0,0,1.846-1.885V-13.32a1.64,1.64,0,0,0-1.846-1.885H2.593A1.64,1.64,0,0,0,.747-13.32v4.033A1.644,1.644,0,0,0,2.593-7.4ZM-8.452-8.789c-.322,0-.479-.166-.479-.5V-13.33c0-.332.156-.488.479-.488h5.84a.427.427,0,0,1,.479.488v4.043a.434.434,0,0,1-.479.5Zm11.074,0a.433.433,0,0,1-.488-.5V-13.33a.427.427,0,0,1,.488-.488h5.84c.313,0,.469.156.469.488v4.043q0,.5-.469.5ZM-8.472,1.895h5.879A1.644,1.644,0,0,0-.747.01V-4.023A1.64,1.64,0,0,0-2.593-5.908H-8.472a1.64,1.64,0,0,0-1.846,1.885V.01A1.644,1.644,0,0,0-8.472,1.895Zm11.064,0H8.472A1.644,1.644,0,0,0,10.317.01V-4.023A1.64,1.64,0,0,0,8.472-5.908H2.593A1.64,1.64,0,0,0,.747-4.023V.01A1.644,1.644,0,0,0,2.593,1.895ZM-8.452.508c-.322,0-.479-.166-.479-.5V-4.023c0-.342.156-.5.479-.5h5.84c.313,0,.479.156.479.5V.01a.434.434,0,0,1-.479.5Zm11.074,0a.433.433,0,0,1-.488-.5V-4.023c0-.342.156-.5.488-.5h5.84c.313,0,.469.156.469.5V.01q0,.5-.469.5Z'
        transform='translate(10.317 15.205)'
        fill={fill || '#808f94'}
      />
    </SvgIcon>
  );
}
