/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function AccountIcon({ fill }) {
  return (
    <SvgIcon width='23' height='15' viewBox='0 0 23 15'>
      <path
        id='Контур_13763'
        data-name='Контур 13763'
        d='M-1.078-12.362a3.777,3.777,0,0,0,3.6-3.913,3.69,3.69,0,0,0-3.6-3.805,3.71,3.71,0,0,0-3.6,3.822A3.772,3.772,0,0,0-1.078-12.362Zm-9.592.183a3.3,3.3,0,0,0,3.122-3.43,3.228,3.228,0,0,0-3.122-3.339,3.254,3.254,0,0,0-3.122,3.356A3.286,3.286,0,0,0-10.67-12.179Zm9.592-1.44a2.481,2.481,0,0,1-2.256-2.639,2.4,2.4,0,0,1,2.256-2.565,2.393,2.393,0,0,1,2.256,2.548A2.492,2.492,0,0,1-1.078-13.619Zm-9.592.2a2.054,2.054,0,0,1-1.882-2.173,2.009,2.009,0,0,1,1.882-2.115,1.989,1.989,0,0,1,1.89,2.1A2.066,2.066,0,0,1-10.67-13.419Zm-4.638,8.876h6.536a1.3,1.3,0,0,1-.641-1.232h-6.095c-.175,0-.25-.067-.25-.225,0-2.065,2.356-4,5.079-4a5.469,5.469,0,0,1,2.8.724,4.151,4.151,0,0,1,.941-.883,6.751,6.751,0,0,0-3.739-1.074c-3.489,0-6.32,2.531-6.32,5.3C-17-5-16.432-4.544-15.308-4.544Zm8.992,0H4.142c1.391,0,2.048-.416,2.048-1.332,0-2.182-2.756-5.337-7.277-5.337S-8.356-8.057-8.356-5.876C-8.356-4.96-7.7-4.544-6.316-4.544Zm-.4-1.257c-.216,0-.3-.058-.3-.233,0-1.366,2.115-3.922,5.928-3.922S4.85-7.4,4.85-6.034c0,.175-.092.233-.308.233Z'
        transform='translate(16.998 20.081)'
        fill={fill}
      />
    </SvgIcon>
  );
}
