export const timeArr = [
  { code: '00:00', name: '00:00' },
  { code: '00:15', name: '00:15' },
  { code: '00:30', name: '00:30' },
  { code: '00:45', name: '00:45' },
  { code: '01:00', name: '01:00' },
  { code: '01:15', name: '01:15' },
  { code: '01:30', name: '01:30' },
  { code: '01:45', name: '01:45' },
  { code: '02:00', name: '02:00' },
  { code: '02:15', name: '02:15' },
  { code: '02:30', name: '02:30' },
  { code: '02:45', name: '02:45' },
  { code: '03:00', name: '03:00' },
  { code: '03:15', name: '03:15' },
  { code: '03:30', name: '03:30' },
  { code: '03:45', name: '03:45' },
  { code: '04:00', name: '04:00' },
  { code: '04:15', name: '04:15' },
  { code: '04:30', name: '04:30' },
  { code: '04:45', name: '04:45' },
  { code: '05:00', name: '05:00' },
  { code: '05:15', name: '05:15' },
  { code: '05:30', name: '05:30' },
  { code: '05:45', name: '05:45' },
  { code: '06:00', name: '06:00' },
  { code: '06:15', name: '06:15' },
  { code: '06:30', name: '06:30' },
  { code: '06:45', name: '06:45' },
  { code: '07:00', name: '07:00' },
  { code: '07:15', name: '07:15' },
  { code: '07:30', name: '07:30' },
  { code: '07:45', name: '07:45' },
  { code: '08:00', name: '08:00' },
  { code: '08:15', name: '08:15' },
  { code: '08:30', name: '08:30' },
  { code: '08:45', name: '08:45' },
  { code: '09:00', name: '09:00' },
  { code: '09:15', name: '09:15' },
  { code: '09:30', name: '09:30' },
  { code: '09:45', name: '09:45' },
  { code: '10:00', name: '10:00' },
  { code: '10:15', name: '10:15' },
  { code: '10:30', name: '10:30' },
  { code: '10:45', name: '10:45' },
  { code: '11:00', name: '11:00' },
  { code: '11:15', name: '11:15' },
  { code: '11:30', name: '11:30' },
  { code: '11:45', name: '11:45' },
  { code: '12:00', name: '12:00' },
  { code: '12:15', name: '12:15' },
  { code: '12:30', name: '12:30' },
  { code: '12:45', name: '12:45' },
  { code: '13:00', name: '13:00' },
  { code: '13:15', name: '13:15' },
  { code: '13:30', name: '13:30' },
  { code: '13:45', name: '13:45' },
  { code: '14:00', name: '14:00' },
  { code: '14:15', name: '14:15' },
  { code: '14:30', name: '14:30' },
  { code: '14:45', name: '14:45' },
  { code: '15:00', name: '15:00' },
  { code: '15:15', name: '15:15' },
  { code: '15:30', name: '15:30' },
  { code: '15:45', name: '15:45' },
  { code: '16:00', name: '16:00' },
  { code: '16:15', name: '16:15' },
  { code: '16:30', name: '16:30' },
  { code: '16:45', name: '16:45' },
  { code: '17:00', name: '17:00' },
  { code: '17:15', name: '17:15' },
  { code: '17:30', name: '17:30' },
  { code: '17:45', name: '17:45' },
  { code: '18:00', name: '18:00' },
  { code: '18:15', name: '18:15' },
  { code: '18:30', name: '18:30' },
  { code: '18:45', name: '18:45' },
  { code: '19:00', name: '19:00' },
  { code: '19:15', name: '19:15' },
  { code: '19:30', name: '19:30' },
  { code: '19:45', name: '19:45' },
  { code: '20:00', name: '20:00' },
  { code: '20:15', name: '20:15' },
  { code: '20:30', name: '20:30' },
  { code: '20:45', name: '20:45' },
  { code: '21:00', name: '21:00' },
  { code: '21:15', name: '21:15' },
  { code: '21:30', name: '21:30' },
  { code: '21:45', name: '21:45' },
  { code: '22:00', name: '22:00' },
  { code: '22:15', name: '22:15' },
  { code: '22:30', name: '22:30' },
  { code: '22:45', name: '22:45' },
  { code: '23:00', name: '23:00' },
  { code: '23:15', name: '23:15' },
  { code: '23:30', name: '23:30' },
  { code: '23:45', name: '23:45' },
];
export const timeHoursArr = [
  { code: '00:00', name: '00:00' },
  { code: '01:00', name: '01:00' },
  { code: '02:00', name: '02:00' },
  { code: '03:00', name: '03:00' },
  { code: '04:00', name: '04:00' },
  { code: '05:00', name: '05:00' },
  { code: '06:00', name: '06:00' },
  { code: '07:00', name: '07:00' },
  { code: '08:00', name: '08:00' },
  { code: '09:00', name: '09:00' },
  { code: '10:00', name: '10:00' },
  { code: '11:00', name: '11:00' },
  { code: '12:00', name: '12:00' },
  { code: '13:00', name: '13:00' },
  { code: '14:00', name: '14:00' },
  { code: '15:00', name: '15:00' },
  { code: '16:00', name: '16:00' },
  { code: '17:00', name: '17:00' },
  { code: '18:00', name: '18:00' },
  { code: '19:00', name: '19:00' },
  { code: '20:00', name: '20:00' },
  { code: '21:00', name: '21:00' },
  { code: '22:00', name: '22:00' },
  { code: '23:00', name: '23:00' },
];
