export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

export const POST_ORGANIZATION = 'POST_ORGANIZATION';
export const POST_ORGANIZATION_SUCCESS = 'POST_ORGANIZATION_SUCCESS';
export const POST_ORGANIZATION_ERROR = 'POST_ORGANIZATION_ERROR';

export const ADD_SNACKBAR = 'ADD_SNACKBAR';
export const DISMISS_SNACKBAR = 'DISMISS_SNACKBAR';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR';

export const POST_CHANGE_OF_STATUS = 'POST_CHANGE_OF_STATUS';
export const POST_CHANGE_OF_STATUS_SUCCESS = 'POST_CHANGE_OF_STATUS_SUCCESS';
export const POST_CHANGE_OF_STATUS_ERROR = 'POST_CHANGE_OF_STATUS_ERROR';

export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR';

export const PATCH_MODULES = 'PATCH_MODULES';
export const PATCH_MODULES_SUCCESS = 'PATCH_MODULES_SUCCESS';
export const PATCH_MODULES_ERROR = 'PATCH_MODULES_ERROR';
