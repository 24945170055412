/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const RemoveRoleIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 14.406 16.523' className={className}>
    <path
      id='remove_svg'
      data-name='remove'
      d='M13.578.5l.508-10.719h.867a.59.59,0,0,0,.586-.586.592.592,0,0,0-.586-.594H11.6v-1.07a1.73,1.73,0,0,0-1.914-1.789H6.969a1.73,1.73,0,0,0-1.914,1.789v1.07H1.719a.592.592,0,0,0-.586.594.59.59,0,0,0,.586.586h.867L3.094.508A1.723,1.723,0,0,0,4.945,2.266h6.781A1.738,1.738,0,0,0,13.578.5ZM6.3-12.4a.7.7,0,0,1,.75-.711H9.609a.7.7,0,0,1,.75.711v1H6.3ZM5.07,1.086a.752.752,0,0,1-.758-.75L3.8-10.219h9.031L12.352.336a.733.733,0,0,1-.758.75ZM10.523-.18a.48.48,0,0,0,.453-.5l.219-7.7a.467.467,0,0,0-.445-.508.491.491,0,0,0-.453.5l-.219,7.7A.462.462,0,0,0,10.523-.18Zm-4.375,0a.467.467,0,0,0,.445-.508l-.219-7.7a.5.5,0,0,0-.453-.5.458.458,0,0,0-.445.508L5.7-.68A.47.47,0,0,0,6.148-.18Zm2.188,0A.489.489,0,0,0,8.8-.687V-8.375a.489.489,0,0,0-.461-.508.489.489,0,0,0-.461.508V-.687A.489.489,0,0,0,8.336-.18Z'
      transform='translate(-1.133 14.258)'
    />
  </SvgIcon>
);

export default RemoveRoleIcon;
