/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '2em',
    height: '2em',
  },
}));

export default function Categories2() {
  const classes = useStyles();
  return (
    <SvgIcon viewBox='0 0 32.705 30.281' className={classes.root}>
      <path
        id='13579'
        data-name='13579'
        d='M-5.811-17.681a4.772,4.772,0,0,0,4.731-4.731,4.772,4.772,0,0,0-4.731-4.731,4.783,4.783,0,0,0-4.731,4.731A4.772,4.772,0,0,0-5.811-17.681Zm11.621,0a4.772,4.772,0,0,0,4.731-4.731,4.772,4.772,0,0,0-4.731-4.731,4.772,4.772,0,0,0-4.731,4.731A4.772,4.772,0,0,0,5.811-17.681ZM0-7.271A4.762,4.762,0,0,0,4.731-12,4.762,4.762,0,0,0,0-16.734,4.762,4.762,0,0,0-4.731-12,4.762,4.762,0,0,0,0-7.271Zm-11.621,0A4.776,4.776,0,0,0-6.873-12a4.776,4.776,0,0,0-4.748-4.731A4.772,4.772,0,0,0-16.353-12,4.772,4.772,0,0,0-11.621-7.271Zm23.242,0A4.762,4.762,0,0,0,16.353-12a4.762,4.762,0,0,0-4.731-4.731A4.762,4.762,0,0,0,6.89-12,4.762,4.762,0,0,0,11.621-7.271ZM-5.811,3.138A4.772,4.772,0,0,0-1.079-1.594,4.758,4.758,0,0,0-5.811-6.309a4.769,4.769,0,0,0-4.731,4.715A4.772,4.772,0,0,0-5.811,3.138Zm11.621,0a4.772,4.772,0,0,0,4.731-4.731A4.758,4.758,0,0,0,5.811-6.309,4.758,4.758,0,0,0,1.079-1.594,4.772,4.772,0,0,0,5.811,3.138Z'
        transform='translate(16.353 27.144)'
        fill='rgba(226,235,244,0.85)'
      />
    </SvgIcon>
  );
}
