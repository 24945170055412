import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PdfIcon = ({ className }) => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <SvgIcon viewBox='0 0 19.533 23.995' className={className}>
    <g id='g899' transform='translate(0 0)'>
      <g id='g876' transform='translate(0 0)'>
        <path
          id='path890'
          d='M-51.068,183.976a2.483,2.483,0,0,0-2.48,2.479v19.037a2.483,2.483,0,0,0,2.48,2.479H-36.5a2.483,2.483,0,0,0,2.48-2.479V189.838a2.05,2.05,0,0,0-.16-.9,2.507,2.507,0,0,0-.491-.709l0,0-3.615-3.542-.006-.006a2.86,2.86,0,0,0-.766-.49,2.8,2.8,0,0,0-1.094-.207H-51.068Z'
          transform='translate(53.548 -183.976)'
          fill='#ff2116'
        />
        <path
          id='rect2684'
          d='M-48.74,187.118h10.933a2.331,2.331,0,0,1,.756.141,2.074,2.074,0,0,1,.527.333v0l3.606,3.534a1.988,1.988,0,0,1,.323.465,1.763,1.763,0,0,1,.09.539q0,.009,0,.017V207.82a1.652,1.652,0,0,1-1.665,1.665H-48.74a1.652,1.652,0,0,1-1.665-1.665V188.783a1.652,1.652,0,0,1,1.665-1.665Z'
          transform='translate(51.22 -186.304)'
          fill='#f5f5f5'
        />
        <path
          id='path2697'
          d='M-35.326,211.479c-.561-.56.046-1.33,1.694-2.148l1.037-.514.4-.882c.222-.485.554-1.277.737-1.76l.333-.877-.229-.649a4.631,4.631,0,0,1-.2-2.429c.242-.584,1.036-.524,1.351.1a3.951,3.951,0,0,1-.071,2.492l-.239.916.21.356a12.364,12.364,0,0,0,.75,1.034l.558.693.695-.091c2.207-.288,2.963.2,2.963.9,0,.884-1.733.957-3.188-.063a4.413,4.413,0,0,1-.552-.458s-.912.185-1.361.306-.694.2-1.373.431c0,0-.238.345-.393.6a6.425,6.425,0,0,1-1.733,1.989A1.183,1.183,0,0,1-35.326,211.479Zm.881-.314a6.632,6.632,0,0,0,1.4-1.648l.179-.283-.815.409c-1.258.632-1.834,1.227-1.535,1.587C-35.052,211.431-34.851,211.415-34.446,211.165Zm8.174-2.291a.472.472,0,0,0-.085-.826,2.293,2.293,0,0,0-1.2-.154c-.433.029-1.13.117-1.248.143,0,0,.383.264.553.361a7.352,7.352,0,0,0,1.177.491c.4.121.625.108.8-.016Zm-3.287-1.364a8.052,8.052,0,0,1-.706-.909,5.063,5.063,0,0,1-.4-.588s-.192.618-.35.99l-.493,1.216-.143.276s.759-.249,1.146-.349c.409-.107,1.24-.27,1.24-.27Zm-1.059-4.241a1.778,1.778,0,0,0-.061-1c-.356-.389-.786-.065-.713.86a6.3,6.3,0,0,0,.205,1.17l.188.6.132-.449A11.754,11.754,0,0,0-30.617,203.27Z'
          transform='translate(40.199 -197.192)'
          fill='#ff2116'
        />
        <g id='g858' transform='translate(5.833 16.881)'>
          <path
            id='path845'
            d='M-31.067,249.125h.91a2.473,2.473,0,0,1,.708.083.831.831,0,0,1,.457.363,1.194,1.194,0,0,1,.186.675,1.228,1.228,0,0,1-.151.624.885.885,0,0,1-.406.377,1.981,1.981,0,0,1-.78.116h-.315V252.8h-.608Zm.608.471v1.281h.3a.794.794,0,0,0,.557-.151.658.658,0,0,0,.156-.49.735.735,0,0,0-.1-.409.419.419,0,0,0-.226-.194,1.415,1.415,0,0,0-.385-.038Z'
            transform='translate(31.067 -249.125)'
            fill='#2c2c2c'
          />
          <path
            id='path847'
            d='M-20.547,249.125h.826a1.868,1.868,0,0,1,.958.213,1.322,1.322,0,0,1,.544.632,2.277,2.277,0,0,1,.186.931,2.6,2.6,0,0,1-.167.961,1.528,1.528,0,0,1-.506.678,1.573,1.573,0,0,1-.969.258h-.872Zm.608.487v2.7h.253a.852.852,0,0,0,.77-.366,1.781,1.781,0,0,0,.24-.982q0-1.351-1.009-1.351Z'
            transform='translate(23.273 -249.125)'
            fill='#2c2c2c'
          />
          <path
            id='path849'
            d='M-8.593,249.125h2.04v.487H-7.984v1.1h1.146v.487H-7.984v1.6h-.608Z'
            transform='translate(14.416 -249.125)'
            fill='#2c2c2c'
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default PdfIcon;
