/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 40.806 41.357' className={className}>
    <path id='Контур_1' data-name='Контур 1' d='M1142.872,742.205h-1.5a16.839,16.839,0,1,1-4.932-11.906,16.778,16.778,0,0,1,4.932,11.906h3a19.838,19.838,0,1,0-19.838,19.838,19.838,19.838,0,0,0,19.838-19.838Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_2' data-name='Контур 2' d='M1126.034,760.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_3' data-name='Контур 3' d='M1126.034,727.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_4' data-name='Контур 4' d='M1142.387,740.705h-2.706a1.5,1.5,0,0,0,0,3h2.706a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_5' data-name='Контур 5' d='M1109.387,740.705h-2.706a1.5,1.5,0,0,0,0,3h2.706a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_6' data-name='Контур 6' d='M1126.034,742.055V731.417a1.5,1.5,0,0,0-3,0v10.638A1.5,1.5,0,0,0,1126.034,742.055Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_7' data-name='Контур 7' d='M1124.534,743.712h8.2a1.5,1.5,0,0,0,0-3h-8.2A1.5,1.5,0,0,0,1124.534,743.712Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_8' data-name='Контур 8' d='M1128.349,741.912a3.612,3.612,0,1,1-3.611-3.611A3.612,3.612,0,0,1,1128.349,741.912Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <circle id='Эллипс_2' data-name='Эллипс 2' cx='6.26' cy='6.26' r='6.26' transform='translate(0 4.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_9' data-name='Контур 9' d='M1118,729.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.736,4.736,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_10' data-name='Контур 10' d='M1113.431,729h-4.712a.691.691,0,0,0,0,1.382h4.712A.691.691,0,0,0,1113.431,729Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_11' data-name='Контур 11' d='M1111.468,727.37l2.813,2.813v-.978l-2.813,2.813a.692.692,0,0,0,.978.978l2.813-2.813a.7.7,0,0,0,0-.978l-2.813-2.813A.692.692,0,0,0,1111.468,727.37Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <circle id='Эллипс_3' data-name='Эллипс 3' cx='6.26' cy='6.26' r='6.26' transform='translate(0 29.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_12' data-name='Контур 12' d='M1118,754.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.736,4.736,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_13' data-name='Контур 13' d='M1112.792,752.539l-3.332,3.332a.692.692,0,0,0,.978.978q1.665-1.667,3.332-3.332A.692.692,0,0,0,1112.792,752.539Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
    <path id='Контур_14' data-name='Контур 14' d='M1113.77,755.871q-1.666-1.666-3.332-3.332a.692.692,0,0,0-.978.978l3.332,3.332A.692.692,0,0,0,1113.77,755.871Z' transform='translate(-1103.566 -721.516)' fill='#faac2e' />
  </SvgIcon>
);
