/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className, fill }) => (
  <SvgIcon width='15' height='19' viewBox='0 0 15 19' className={className} data-name='logbook-icon'>
    <path
      id='Вычитание_7'
      data-name='Вычитание 7'
      d='M-10746.33,3654h-8.336a3.325,3.325,0,0,1-3.337-3.305V3638.3a3.325,3.325,0,0,1,3.337-3.305h4.436c.707,0,1.934.892,3.752,2.728.153.155.321.322.5.5l.146.147.01.009c.532.531,1.135,1.134,1.729,1.742,0,0,.471.531.8,1a3.6,3.6,0,0,1,.323,1.577l-.026,8A3.323,3.323,0,0,1-10746.33,3654Zm-8.389-17.954a2.216,2.216,0,0,0-2.5,2.5v11.9a2.22,2.22,0,0,0,2.5,2.509h8.443a2.219,2.219,0,0,0,2.5-2.509v-7.095h-5.812c-1.019,0-1.492-.47-1.492-1.479v-5.825Zm4.754.076v5.689a.389.389,0,0,0,.435.442h5.66a2.02,2.02,0,0,0-.68-1.055l-4.359-4.4a2.106,2.106,0,0,0-1.056-.68Zm2.53,13.634h-6.12a.551.551,0,0,1-.569-.557.548.548,0,0,1,.569-.549h6.12a.555.555,0,0,1,.571.549A.559.559,0,0,1-10747.435,3649.756Zm0-3.009h-6.12a.547.547,0,0,1-.569-.549.546.546,0,0,1,.569-.546h6.12a.547.547,0,0,1,.571.546A.554.554,0,0,1-10747.435,3646.747Z'
      transform='translate(10758.203 -3634.8)'
      fill={fill || '#69767a'}
      stroke={fill || '#69767a'}
      strokeWidth='0.4'
    />
  </SvgIcon>
);
