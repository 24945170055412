/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function GenaralIcon({ fill }) {
  return (
    <SvgIcon viewBox='0 0 18.936 18.936'>
      <path
        id='Контур_13459'
        data-name='Контур 13459'
        d='M0,2.8A9.53,9.53,0,0,0,9.468-6.67,9.537,9.537,0,0,0,0-16.133,9.528,9.528,0,0,0-9.468-6.67,9.539,9.539,0,0,0,0,2.8ZM0,1.113A7.746,7.746,0,0,1-7.769-6.67,7.744,7.744,0,0,1,0-14.453,7.775,7.775,0,0,1,7.788-6.67,7.748,7.748,0,0,1,0,1.113ZM-.073-9.9a1.215,1.215,0,0,0,1.221-1.221,1.211,1.211,0,0,0-1.221-1.23,1.217,1.217,0,0,0-1.221,1.23A1.221,1.221,0,0,0-.073-9.9ZM-1.626-1.6h3.76a.678.678,0,0,0,.7-.684.68.68,0,0,0-.7-.664H1.03v-4.59C1.03-8.057.767-8.4.288-8.4H-1.46a.672.672,0,0,0-.693.664.67.67,0,0,0,.693.684h.967v4.1H-1.626a.678.678,0,0,0-.693.664A.676.676,0,0,0-1.626-1.6Z'
        transform='translate(9.468 16.133)'
        fill={fill || '#808f94'}
      />
    </SvgIcon>
  );
}
