/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ApproveIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 27.277 27.277' className={className}>
    <path
      id='Контур_13269'
      data-name='Контур 13269'
      d='M-16,4.126A13.721,13.721,0,0,0-2.36-9.505,13.735,13.735,0,0,0-16.018-23.15,13.71,13.71,0,0,0-29.637-9.505,13.718,13.718,0,0,0-16,4.126ZM-17.8-2.624A1.62,1.62,0,0,1-19.077-3.3l-3.8-4.627a1.487,1.487,0,0,1-.356-.936A1.23,1.23,0,0,1-22-10.112a1.35,1.35,0,0,1,1.081.567l3.085,3.889,5.946-9.492a1.271,1.271,0,0,1,1.081-.7,1.227,1.227,0,0,1,1.292,1.16,1.849,1.849,0,0,1-.343.936l-6.7,10.415A1.445,1.445,0,0,1-17.8-2.624Z'
      transform='translate(29.637 23.15)'
      fill='#57d05a'
    />
  </SvgIcon>
);

export default ApproveIcon;
