/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({
  viewBox,
  fill,
  width = 27,
  height = 24,
  className,
}) => (
  <SvgIcon viewBox={viewBox || '0 0 22.595 19.099'} style={{ width, height }} className={className}>
    <g id='Сгруппировать_2498' data-name='schedule' transform='translate(-45.702 -726.901)'>
      <g id='icon_schedule' data-name='icon / schedule' transform='translate(45.702 726.901)'>
        <path id='Контур_13718' data-name='Контур 13718' d='M-9.553-4.72H1.808A2.291,2.291,0,0,0,4.411-7.3V-17.454a2.291,2.291,0,0,0-2.6-2.578H-9.553a2.294,2.294,0,0,0-2.611,2.578V-7.3A2.294,2.294,0,0,0-9.553-4.72Zm-.125-1.339a1.033,1.033,0,0,1-1.148-1.164v-7.843a1.037,1.037,0,0,1,1.148-1.164H1.916a1.043,1.043,0,0,1,1.156,1.164v7.843A1.039,1.039,0,0,1,1.916-6.059ZM-5.5-13.245h.491c.291,0,.391-.083.391-.374v-.491c0-.291-.1-.383-.391-.383H-5.5c-.291,0-.391.091-.391.383v.491C-5.893-13.328-5.794-13.245-5.5-13.245Zm2.77,0h.482c.291,0,.391-.083.391-.374v-.491c0-.291-.1-.383-.391-.383h-.482c-.3,0-.391.091-.391.383v.491C-3.124-13.328-3.032-13.245-2.733-13.245Zm2.761,0H.519c.291,0,.383-.083.383-.374v-.491c0-.291-.091-.383-.383-.383H.028c-.291,0-.391.091-.391.383v.491C-.363-13.328-.263-13.245.028-13.245Zm-8.292,2.72h.491c.291,0,.383-.083.383-.374v-.491c0-.291-.091-.374-.383-.374h-.491c-.291,0-.391.083-.391.374v.491C-8.655-10.609-8.555-10.526-8.264-10.526Zm2.761,0h.491c.291,0,.391-.083.391-.374v-.491c0-.291-.1-.374-.391-.374H-5.5c-.291,0-.391.083-.391.374v.491C-5.893-10.609-5.794-10.526-5.5-10.526Zm2.77,0h.482c.291,0,.391-.083.391-.374v-.491c0-.291-.1-.374-.391-.374h-.482c-.3,0-.391.083-.391.374v.491C-3.124-10.609-3.032-10.526-2.733-10.526Zm2.761,0H.519c.291,0,.383-.083.383-.374v-.491c0-.291-.091-.374-.383-.374H.028c-.291,0-.391.083-.391.374v.491C-.363-10.609-.263-10.526.028-10.526ZM-8.264-7.8h.491c.291,0,.383-.091.383-.383v-.491c0-.291-.091-.374-.383-.374h-.491c-.291,0-.391.083-.391.374v.491C-8.655-7.889-8.555-7.8-8.264-7.8Zm2.761,0h.491c.291,0,.391-.091.391-.383v-.491c0-.291-.1-.374-.391-.374H-5.5c-.291,0-.391.083-.391.374v.491C-5.893-7.889-5.794-7.8-5.5-7.8Zm2.77,0h.482c.291,0,.391-.091.391-.383v-.491c0-.291-.1-.374-.391-.374h-.482c-.3,0-.391.083-.391.374v.491C-3.124-7.889-3.032-7.8-2.733-7.8Z' transform='translate(12.164 20.032)' fill={fill || '#808f94'} />
        <g id='Эллипс_7' data-name='Эллипс 7' transform='translate(10.332 6.835)' fill='#fff' stroke={fill || '#808f94'} strokeWidth='1.5'>
          <ellipse cx='6.132' cy='6.132' rx='6.132' ry='6.132' stroke='none' />
          <ellipse cx='6.132' cy='6.132' rx='5.382' ry='5.382' fill='none' />
        </g>
      </g>
      <path
        id='Контур_13723'
        data-name='Контур 13723'
        d='M1062-1812.111v2.433l1.578,1.578'
        transform='translate(-999.834 2549.859)'
        fill='none'
        stroke={fill || '#808f94'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
  </SvgIcon>
);
