/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const SuspendIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 27.277 27.277' className={className}>
    <path
      id='Контур_13270'
      data-name='Контур 13270'
      d='M-16,4.126A13.721,13.721,0,0,0-2.36-9.505,13.735,13.735,0,0,0-16.018-23.15,13.71,13.71,0,0,0-29.637-9.505,13.718,13.718,0,0,0-16,4.126Zm-4.575-7.8a1.255,1.255,0,0,1-1.252-1.266,1.174,1.174,0,0,1,.369-.87l3.678-3.691-3.678-3.678a1.2,1.2,0,0,1-.369-.87,1.235,1.235,0,0,1,1.252-1.252,1.162,1.162,0,0,1,.883.369l3.678,3.665,3.7-3.678a1.167,1.167,0,0,1,.883-.369,1.247,1.247,0,0,1,1.266,1.252,1.167,1.167,0,0,1-.369.87l-3.678,3.691,3.665,3.665a1.256,1.256,0,0,1,.369.9A1.255,1.255,0,0,1-11.43-3.678a1.273,1.273,0,0,1-.91-.369l-3.678-3.665L-19.67-4.047A1.221,1.221,0,0,1-20.58-3.678Z'
      transform='translate(29.637 23.15)'
      fill='#f4373e'
    />
  </SvgIcon>
);

export default SuspendIcon;
