import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const ProfitIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 15.148 15.148' className={className}>
    <path id="Path_13145" data-name="Path 13145" fill="#32c536" d="M8.9,2.242a7.624,7.624,0,0,0,7.57-7.578,7.629,7.629,0,0,0-7.578-7.57,7.623,7.623,0,0,0-7.57,7.57A7.631,7.631,0,0,0,8.9,2.242ZM8.891-.82A.363.363,0,0,1,8.508-1.2V-1.75A2.344,2.344,0,0,1,6.32-3.336a1.369,1.369,0,0,1-.031-.258.467.467,0,0,1,.492-.492.518.518,0,0,1,.516.414A1.359,1.359,0,0,0,8.508-2.7v-2.25L8.43-4.969c-1.32-.32-2.008-.914-2.008-1.937A2.077,2.077,0,0,1,8.508-8.891V-9.43a.369.369,0,0,1,.383-.383.372.372,0,0,1,.375.383v.539A2.253,2.253,0,0,1,11.32-7.336a1.276,1.276,0,0,1,.039.258.476.476,0,0,1-.5.484A.529.529,0,0,1,10.344-7a1.294,1.294,0,0,0-1.078-.93V-5.8l.125.023c1.367.32,2.086.906,2.086,1.984,0,1.211-.953,1.93-2.211,2.047V-1.2A.366.366,0,0,1,8.891-.82ZM8.508-5.992V-7.93c-.641.109-1.031.5-1.031.969,0,.445.289.766.977.953Zm.758,1.227V-2.7c.781-.086,1.164-.492,1.164-1.023,0-.508-.289-.781-1.086-1.023Z" transform="translate(-1.32 12.906)"/>
  </SvgIcon>
);

export default ProfitIcon;
