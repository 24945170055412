// eslint-disable-next-line import/prefer-default-export
export const EVENT_TYPE = {
  EARLY_CLOCK_IN: 1,
  LATE_CLOCK_IN: 2,
  MISSING_CLOCK_IN: 3,
  EARLY_CLOCK_OUT: 4,
  LATE_CLOCK_OUT: 5,
  MISSING_CLOCK_OUT: 6,
  DAILY_OVERTIME_START: 7,
  WEEKLY_OVERTIME_START: 8,
  REMINDER_TO_CLOCK_IN: 9,
  REMINDER_TO_CLOCK_OUT: 10,
};

export const TIMES_PANEL = [
  'total',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
];

export const TIMELINE = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

export const COLORS_SHIFT = {
  bright: [
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
    '#3D3D66',
    '#3D4B66',
    '#3D5866',
    '#3D6666',
    '#3D6658',
    '#3D664B',
    '#3D663D',
    '#4B663D',
    '#58663D',
    '#66663D',
    '#66583D',
    '#664B3D',
    '#663D3D',
    '#663D4A',
    '#663D58',
    '#663D66',
    '#583D66',
    '#4B3D66',
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
  ],
  calm: [
    '#595980',
    '#596680',
    '#597380',
    '#598080',
    '#598073',
    '#598066',
    '#598059',
    '#668059',
    '#738059',
    '#808059',
    '#807359',
    '#806659',
    '#805959',
    '#805965',
    '#805973',
    '#805980',
    '#735980',
    '#665980',
  ],
};

export const COLORS_JOB_TYPE = {
  bright: [
    '#3D3DF2',
    '#3D79F2',
    '#3DB6F2',
    '#3DF2F2',
    '#3DF2B6',
    '#3DF279',
    '#3DF23D',
    '#79F23D',
    '#B6F23D',
    '#F2F23D',
    '#F2B63D',
    '#F2793D',
    '#F23D3D',
    '#F23D79',
    '#F23DB6',
    '#F23DF2',
    '#B63DF2',
    '#793DF2',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#3D3DF2',
    '#3D79F2',
    '#3DB6F2',
    '#3DF2F2',
    '#3DF2B6',
    '#3DF279',
    '#3DF23D',
    '#79F23D',
    '#B6F23D',
    '#F2F23D',
    '#F2B63D',
    '#F2793D',
    '#F23D3D',
    '#F23D79',
    '#F23DB6',
    '#F23DF2',
    '#B63DF2',
    '#793DF2',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#ADC0CC',
    '#E7EAED'
  ],
  calm: [
    '#5252CC',
    '#527ACC',
    '#52A3CC',
    '#52CCCC',
    '#52CCA3',
    '#52CC7A',
    '#52CC52',
    '#7ACC52',
    '#A3CC52',
    '#CCCC52',
    '#CCA352',
    '#CC7A52',
    '#CC5252',
    '#CC527A',
    '#CC52A3',
    '#CC52CC',
    '#7A3D99',
    '#7A52CC',
    '#ADC0CC',
    '#E7EAED'
  ],
};
