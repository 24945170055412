import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const InfoIcon = ({ className }) => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <SvgIcon viewBox='0 0 24.902 24.902' className={className}>
    <path
      id='Path_13287'
      data-name='Path 13287'
      d='M0,3.638A12.535,12.535,0,0,0,12.451-8.813,12.554,12.554,0,0,0-.012-21.265,12.541,12.541,0,0,0-12.451-8.813,12.543,12.543,0,0,0,0,3.638ZM0,1.563A10.326,10.326,0,0,1-10.364-8.813,10.323,10.323,0,0,1-.012-19.189,10.362,10.362,0,0,1,10.376-8.813,10.329,10.329,0,0,1,0,1.563ZM-.11-13.159a1.576,1.576,0,0,0,1.587-1.587,1.579,1.579,0,0,0-1.587-1.6,1.586,1.586,0,0,0-1.587,1.6A1.584,1.584,0,0,0-.11-13.159ZM-2.136-2.1H2.832a.851.851,0,0,0,.891-.854.866.866,0,0,0-.891-.854H1.318v-6.213c0-.659-.33-1.1-.952-1.1H-1.929a.866.866,0,0,0-.891.854.851.851,0,0,0,.891.854H-.623v5.6H-2.136a.866.866,0,0,0-.891.854A.851.851,0,0,0-2.136-2.1Z'
      transform='translate(12.451 21.265)'
      fill='#0085ff'
    />
  </SvgIcon>
);

export default InfoIcon;
