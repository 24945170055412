/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 18.167 16.465' className={className}>
    <path
      id='Контур_13640'
      data-name='Контур 13640'
      d='M-10.364-3.945H3.007a2.3,2.3,0,0,0,2.4-2.4,2.349,2.349,0,0,0-.321-1.182l-6.7-11.669A2.372,2.372,0,0,0-3.674-20.41a2.375,2.375,0,0,0-2.068,1.216l-6.7,11.677a2.318,2.318,0,0,0-.321,1.173A2.3,2.3,0,0,0-10.364-3.945Zm.009-1.364a1.006,1.006,0,0,1-1-1.034,1.205,1.205,0,0,1,.113-.521l6.69-11.669a1,1,0,0,1,.878-.487.954.954,0,0,1,.86.487L3.868-6.856a1.085,1.085,0,0,1,.139.513A1.021,1.021,0,0,1,2.99-5.309ZM-3.674-9.8a.618.618,0,0,0,.66-.7l.13-4.579a.76.76,0,0,0-.8-.773.741.741,0,0,0-.791.765l.113,4.588A.633.633,0,0,0-3.674-9.8Zm0,2.824a.933.933,0,0,0,.938-.9.922.922,0,0,0-.938-.9.922.922,0,0,0-.938.9A.933.933,0,0,0-3.674-6.978Z'
      transform='translate(12.762 20.41)'
      fill='#faac2e'
    />
  </SvgIcon>
);
