/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 39.677 39.679' className={className}>
    <path id='Контур_29' data-name='Контур 29' d='M1123.731,859.044a16.865,16.865,0,1,1,13.594-6.9l2.421,1.772a19.84,19.84,0,1,0-16.015,8.127v-3Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_30' data-name='Контур 30' d='M1125.231,860.349v-3a1.5,1.5,0,0,0-3,0v3A1.5,1.5,0,0,0,1125.231,860.349Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_31' data-name='Контур 31' d='M1125.231,827.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_32' data-name='Контур 32' d='M1141.584,840.705h-2.7a1.5,1.5,0,0,0,0,3h2.7a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_33' data-name='Контур 33' d='M1108.584,840.705h-2.7a1.5,1.5,0,0,0,0,3h2.7a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_34' data-name='Контур 34' d='M1141.879,852.2l-4.547.963,1.538.873-.949-4.482c-.334-1.577-2.743-.908-2.411.664q.476,2.241.949,4.482a1.263,1.263,0,0,0,1.538.873l4.547-.962C1144.121,854.278,1143.452,851.868,1141.879,852.2Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_35' data-name='Контур 35' d='M1125.229,860.5a1.544,1.544,0,1,1-1.544-1.544A1.544,1.544,0,0,1,1125.229,860.5Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <rect id='Прямоугольник_1' data-name='Прямоугольник 1' width='17.876' height='13.127' rx='1.282' transform='translate(10.901 13.286)' fill='#faac2e' />
    <path id='Контур_36' data-name='Контур 36' d='M1121.231,837.055v-3.638a1.5,1.5,0,0,0-3,0v3.638A1.5,1.5,0,0,0,1121.231,837.055Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_37' data-name='Контур 37' d='M1121.231,837.055h.5v-3.638a1.885,1.885,0,0,0-.642-1.467,2.147,2.147,0,0,0-2.715,0,1.89,1.89,0,0,0-.643,1.468v3.638a1.888,1.888,0,0,0,.643,1.467,2.146,2.146,0,0,0,2.715,0,1.887,1.887,0,0,0,.642-1.467h-1a.881.881,0,0,1-.294.709,1.149,1.149,0,0,1-1.411,0,.882.882,0,0,1-.3-.709v-3.638a.879.879,0,0,1,.3-.709,1.149,1.149,0,0,1,1.411,0,.878.878,0,0,1,.294.709v3.638Z' transform='translate(-1103.893 -822.367)' fill='#ffeed5' />
    <path id='Контур_38' data-name='Контур 38' d='M1129.231,837.055v-3.638a1.5,1.5,0,0,0-3,0v3.638A1.5,1.5,0,0,0,1129.231,837.055Z' transform='translate(-1103.893 -822.367)' fill='#faac2e' />
    <path id='Контур_39' data-name='Контур 39' d='M1129.231,837.055h.5v-3.638a1.885,1.885,0,0,0-.642-1.467,2.147,2.147,0,0,0-2.715,0,1.89,1.89,0,0,0-.643,1.468v3.638a1.888,1.888,0,0,0,.643,1.467,2.146,2.146,0,0,0,2.715,0,1.887,1.887,0,0,0,.642-1.467h-1a.881.881,0,0,1-.294.709,1.149,1.149,0,0,1-1.411,0,.882.882,0,0,1-.3-.709v-3.638a.879.879,0,0,1,.3-.709,1.149,1.149,0,0,1,1.411,0,.878.878,0,0,1,.294.709v3.638Z' transform='translate(-1103.893 -822.367)' fill='#ffeed5' />
    <text id='_1' data-name='1' transform='translate(18.151 24.179)' fill='#ffeed5' fontSize='10' fontFamily='Helvetica'><tspan x='0' y='0'>1</tspan></text>
  </SvgIcon>
);
