export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';

export const POST_SHIFT = 'POST_SHIFT';
export const POST_SHIFT_SUCCESS = 'POST_SHIFT_SUCCESS';
export const POST_SHIFT_ERROR = 'POST_SHIFT_ERROR';

export const ADD_TEMP_EMPLOYEE = 'ADD_TEMP_EMPLOYEE'
export const ADD_TEMP_EMPLOYEE_SUCCESS = 'ADD_TEMP_EMPLOYEE_SUCCESS'

export const GET_SHIFT = 'GET_SHIFT';
export const GET_SHIFT_SUCCESS = 'GET_SHIFT_SUCCESS';
export const GET_SHIFT_ERROR = 'GET_SHIFT_ERROR';

export const PUT_SHIFT = 'PUT_SHIFT';
export const PUT_SHIFT_SUCCESS = 'PUT_SHIFT_SUCCESS';
export const PUT_SHIFT_ERROR = 'PUT_SHIFT_ERROR';

export const PATCH_MARKER = 'PATCH_MARKER';
export const PATCH_MARKER_SUCCESS = 'PATCH_MARKER_SUCCESS';
export const PATCH_MARKER_ERROR = 'PATCH_MARKER_ERROR';

export const PATCH_CHANGE_EMPLOYEE = 'PATCH_CHANGE_EMPLOYEE';
export const PATCH_CHANGE_EMPLOYEE_SUCCESS = 'PATCH_CHANGE_EMPLOYEE_SUCCESS';
export const PATCH_CHANGE_EMPLOYEE_ERROR = 'PATCH_CHANGE_EMPLOYEE_ERROR';

export const PATCH_CHANGE_TIMELINE = 'PATCH_CHANGE_TIMELINE';
export const PATCH_CHANGE_TIMELINE_SUCCESS = 'PATCH_CHANGE_TIMELINE_SUCCESS';
export const PATCH_CHANGE_TIMELINE_ERROR = 'PATCH_CHANGE_TIMELINE_ERROR';

export const PATCH_ADD_TIMELINE = 'PATCH_ADD_TIMELINE';
export const PATCH_ADD_TIMELINE_SUCCESS = 'PATCH_ADD_TIMELINE_SUCCESS';
export const PATCH_ADD_TIMELINE_ERROR = 'PATCH_ADD_TIMELINE_ERROR';

export const DOWNLOAD_SCHEDULE = 'DOWNLOAD_SCHEDULE';
export const DOWNLOAD_SCHEDULE_SUCCESS = 'DOWNLOAD_SCHEDULE_SUCCESS';
export const DOWNLOAD_SCHEDULE_ERROR = 'DOWNLOAD_SCHEDULE_ERROR';

export const DELETE_TIMELINE = 'DELETE_TIMELINE';
export const DELETE_TIMELINE_SUCCESS = 'DELETE_TIMELINE_SUCCESS';
export const DELETE_TIMELINE_ERROR = 'DELETE_TIMELINE_ERROR';

export const EMPTY_TIMELINE = 'EMPTY_TIMELINE';
export const EMPTY_TIMELINE_SUCCESS = 'EMPTY_TIMELINE_SUCCESS';
export const EMPTY_TIMELINE_ERROR = 'EMPTY_TIMELINE_ERROR';

export const DELETE_SHIFT = 'DELETE_SHIFT';

export const RESET_SHIFT = 'RESET_SHIFT';

export const SET_LOADER = 'SET_LOADER';