/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const AddRolesIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 15.148 15.148' className={className}>
    <path
      id='Контур_13600'
      data-name='Контур 13600'
      d='M8.9,2.242a7.624,7.624,0,0,0,7.57-7.578,7.629,7.629,0,0,0-7.578-7.57,7.623,7.623,0,0,0-7.57,7.57A7.631,7.631,0,0,0,8.9,2.242ZM8.9.891A6.2,6.2,0,0,1,2.68-5.336a6.2,6.2,0,0,1,6.211-6.227,6.22,6.22,0,0,1,6.234,6.227A6.2,6.2,0,0,1,8.9.891ZM8.883-1.828a.636.636,0,0,0,.648-.719V-4.687H11.8a.633.633,0,0,0,.719-.633.634.634,0,0,0-.719-.656H9.531V-8.242a.636.636,0,0,0-.648-.719.638.638,0,0,0-.641.719v2.266H5.984a.644.644,0,0,0-.727.656.643.643,0,0,0,.727.633H8.242v2.141A.638.638,0,0,0,8.883-1.828Z'
      transform='translate(-1.32 12.906)'
      fill='#1685fc'
    />
  </SvgIcon>
);

export default AddRolesIcon;
