import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '16px',
    height: '16px',
  },
}));

const GoogleMarkerIcon = ({ active }) => {
  const classes = useStyles();
  return (
    active ?
    <SvgIcon width="14" height="16" viewBox="0 0 14 16" fill="none" className={classes.root}>
      <path d="M7.00142 7.88747C7.41607 7.88747 7.77056 7.73983 8.0649 7.44455C8.35923 7.14927 8.5064 6.79431 8.5064 6.37965C8.5064 5.965 8.35876 5.61051 8.06348 5.31617C7.7682 5.02184 7.41324 4.87467 6.99859 4.87467C6.58393 4.87467 6.22944 5.02231 5.93511 5.31759C5.64077 5.61287 5.49361 5.96783 5.49361 6.38249C5.49361 6.79714 5.64125 7.15163 5.93652 7.44597C6.2318 7.7403 6.58677 7.88747 7.00142 7.88747ZM7 15.9243C4.90279 14.107 3.33015 12.4158 2.28207 10.8506C1.234 9.28545 0.709961 7.84848 0.709961 6.53972C0.709961 4.61666 1.33202 3.05978 2.57615 1.86907C3.82026 0.678362 5.29488 0.0830078 7 0.0830078C8.70513 0.0830078 10.1797 0.678362 11.4239 1.86907C12.668 3.05978 13.29 4.61666 13.29 6.53972C13.29 7.84848 12.766 9.28545 11.7179 10.8506C10.6699 12.4158 9.09721 14.107 7 15.9243Z" fill="#FD4646"/>
    </SvgIcon>
    :    
    <SvgIcon width="14" height="16" viewBox="0 0 14 16" fill="none" className={classes.root}>
      <path d="M7.00142 7.88747C7.41607 7.88747 7.77056 7.73983 8.0649 7.44455C8.35923 7.14927 8.5064 6.79431 8.5064 6.37965C8.5064 5.965 8.35876 5.61051 8.06348 5.31617C7.7682 5.02184 7.41324 4.87467 6.99859 4.87467C6.58393 4.87467 6.22944 5.02231 5.93511 5.31759C5.64077 5.61287 5.49361 5.96783 5.49361 6.38249C5.49361 6.79714 5.64125 7.15163 5.93652 7.44597C6.2318 7.7403 6.58677 7.88747 7.00142 7.88747ZM7 14.2609C8.63034 12.8015 9.87793 11.4017 10.7428 10.0614C11.6076 8.72108 12.0401 7.5472 12.0401 6.53972C12.0401 5.02049 11.5574 3.77156 10.5921 2.79293C9.62687 1.81429 8.42949 1.32497 7 1.32497C5.57052 1.32497 4.37313 1.81429 3.40786 2.79293C2.44258 3.77156 1.95994 5.02049 1.95994 6.53972C1.95994 7.5472 2.39236 8.72108 3.25721 10.0614C4.12207 11.4017 5.36967 12.8015 7 14.2609ZM7 15.9243C4.90279 14.107 3.33015 12.4158 2.28207 10.8506C1.234 9.28545 0.709961 7.84848 0.709961 6.53972C0.709961 4.61666 1.33202 3.05978 2.57615 1.86907C3.82026 0.678362 5.29488 0.0830078 7 0.0830078C8.70513 0.0830078 10.1797 0.678362 11.4239 1.86907C12.668 3.05978 13.29 4.61666 13.29 6.53972C13.29 7.84848 12.766 9.28545 11.7179 10.8506C10.6699 12.4158 9.09721 14.107 7 15.9243Z" fill="#333945"/>
    </SvgIcon>
  );
}

export default GoogleMarkerIcon;
