/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const OverviewIcon = ({ className }) => (
  <SvgIcon viewBox='0 0 24.902 24.902' className={className}>
    <path
      id='Контур_12985'
      data-name='Контур 12985'
      d='M0,3.638A12.535,12.535,0,0,0,12.451-8.813,12.554,12.554,0,0,0-.012-21.265,12.541,12.541,0,0,0-12.451-8.813,12.543,12.543,0,0,0,0,3.638ZM0,1.563A10.326,10.326,0,0,1-10.364-8.813,10.323,10.323,0,0,1-.012-19.189,10.362,10.362,0,0,1,10.376-8.813,10.329,10.329,0,0,1,0,1.563ZM-.012-14.673a1.214,1.214,0,0,0,1.2-1.2,1.209,1.209,0,0,0-1.2-1.208,1.2,1.2,0,0,0-1.2,1.208A1.206,1.206,0,0,0-.012-14.673Zm-4.956,2.148a1.206,1.206,0,0,0,1.2-1.2,1.19,1.19,0,0,0-1.2-1.2,1.19,1.19,0,0,0-1.2,1.2A1.206,1.206,0,0,0-4.968-12.524Zm9.912,0a1.206,1.206,0,0,0,1.2-1.2,1.19,1.19,0,0,0-1.2-1.2,1.19,1.19,0,0,0-1.2,1.2A1.206,1.206,0,0,0,4.944-12.524ZM-.012-7.544a1.284,1.284,0,0,0,1.27-1.27c0-.073-.012-.134-.012-.208l1.892-2.368c.159-.232.208-.513,0-.684a.487.487,0,0,0-.659,0L.2-10.059a1.2,1.2,0,0,0-.208-.024,1.276,1.276,0,0,0-1.27,1.27A1.276,1.276,0,0,0-.012-7.544Zm-7.031-.073a1.2,1.2,0,0,0,1.208-1.2,1.2,1.2,0,0,0-1.208-1.2,1.206,1.206,0,0,0-1.2,1.2A1.206,1.206,0,0,0-7.043-7.617Zm14.063,0a1.206,1.206,0,0,0,1.2-1.2,1.206,1.206,0,0,0-1.2-1.2,1.19,1.19,0,0,0-1.2,1.2A1.19,1.19,0,0,0,7.019-7.617ZM-4.944-2.7a1.209,1.209,0,0,0,1.2-1.208,1.2,1.2,0,0,0-1.2-1.2,1.2,1.2,0,0,0-1.2,1.2A1.209,1.209,0,0,0-4.944-2.7Zm9.863,0a1.209,1.209,0,0,0,1.2-1.208,1.2,1.2,0,0,0-1.2-1.2,1.2,1.2,0,0,0-1.2,1.2A1.209,1.209,0,0,0,4.919-2.7Z'
      transform='translate(12.451 21.265)'
      fill='#808f94'
    />
  </SvgIcon>
);

export default OverviewIcon;
