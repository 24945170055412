/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ fill }) => (
  <SvgIcon viewBox='0 0 17 24' style={{ width: 22, height: 29 }}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.799999 3C0.799999 1.50883 2.00883 0.299999 3.5 0.299999H12.5C13.9912 0.299999 15.2 1.50883 15.2 3V15L13.8 14.2852V3C13.8 2.28203 13.218 1.7 12.5 1.7H3.5C2.78203 1.7 2.2 2.28203 2.2 3V18C2.2 18.718 2.78203 19.3 3.5 19.3H4L4.87171 20.7H3.5C2.00883 20.7 0.799999 19.4912 0.799999 18V3ZM8 9.7C7.83431 9.7 7.7 9.83431 7.7 10V15.7929C7.7 16.862 6.40743 17.3974 5.65147 16.6414L4.56373 15.5537C4.52853 15.5185 4.47147 15.5185 4.43627 15.5537C4.40676 15.5832 4.40137 15.6291 4.42324 15.6646L7.8033 21.1572C7.8579 21.246 7.95462 21.3 8.05879 21.3H12.4157C12.524 21.3 12.6239 21.2416 12.6771 21.1473L13.9523 18.8849C13.9777 18.8399 13.991 18.7892 13.991 18.7376V17.0249C13.991 16.9126 13.9282 16.8096 13.8283 16.7582L9.99185 14.7831C9.19587 15.1129 8.3 14.5297 8.3 13.6489V10C8.3 9.83431 8.16568 9.7 8 9.7ZM6.3 10C6.3 9.06112 7.06111 8.3 8 8.3C8.93888 8.3 9.7 9.06112 9.7 10V11.5499C9.70473 11.5443 9.70957 11.5387 9.71451 11.5332C10.0791 11.1258 10.3 10.5895 10.3 10C10.3 8.72974 9.27025 7.7 8 7.7C6.72974 7.7 5.7 8.72974 5.7 10C5.7 10.5895 5.92087 11.1258 6.28549 11.5332C6.29043 11.5387 6.29526 11.5443 6.3 11.5499V10ZM6.3 12.4502C6.27892 12.4753 6.25583 12.4991 6.23073 12.5216C5.94266 12.7794 5.50013 12.7549 5.2423 12.4668C4.65683 11.8127 4.3 10.9472 4.3 10C4.3 7.95655 5.95655 6.3 8 6.3C10.0435 6.3 11.7 7.95655 11.7 10C11.7 10.9472 11.3432 11.8127 10.7577 12.4668C10.4999 12.7549 10.0573 12.7794 9.76927 12.5216C9.74417 12.4991 9.72108 12.4753 9.7 12.4502V13.4002C9.98561 13.329 10.2907 13.3623 10.5577 13.4998L14.4691 15.5134C15.0352 15.8049 15.391 16.3882 15.391 17.0249V18.7376C15.391 19.03 15.3155 19.3176 15.1719 19.5723L13.8967 21.8348C13.5954 22.3693 13.0294 22.7 12.4157 22.7H8.05879C7.46845 22.7 6.92037 22.3937 6.61097 21.891L3.23092 16.3984C2.86928 15.8107 2.9584 15.0516 3.44632 14.5637C4.02825 13.9818 4.97175 13.9818 5.55368 14.5637L6.3 15.31V12.4502Z'
      fill={fill}
    />
  </SvgIcon>
);
