/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 39.819 33.656' className={className}>
    <path
      id='Контур_13718'
      data-name='Контур 13718'
      d='M-7.562,6.951H12.459c3.063,0,4.587-1.524,4.587-4.544v-17.9c0-3.019-1.524-4.544-4.587-4.544H-7.562q-4.595,0-4.6,4.544v17.9Q-12.164,6.958-7.562,6.951Zm-.22-2.36c-1.3,0,22.468,1.363,22.468,0h0c0-1.348-1.3,0,0,0h0c1.3,0,0-1.348,0,0V2.539a1.831,1.831,0,0,1-2.037,2.052ZM-.424-8.072H.44c.513,0,.689-.147.689-.66V-9.6c0-.513-.176-.674-.689-.674H-.424c-.513,0-.689.161-.689.674v.865C-1.113-8.219-.937-8.072-.424-8.072Zm4.881,0h.85c.513,0,.689-.147.689-.66V-9.6c0-.513-.176-.674-.689-.674h-.85c-.528,0-.689.161-.689.674v.865C3.767-8.219,3.929-8.072,4.456-8.072Zm4.866,0h.865c.513,0,.674-.147.674-.66V-9.6c0-.513-.161-.674-.674-.674H9.322c-.513,0-.689.161-.689.674v.865C8.633-8.219,8.809-8.072,9.322-8.072ZM-5.29-3.279h.865c.513,0,.674-.147.674-.66V-4.8c0-.513-.161-.66-.674-.66H-5.29c-.513,0-.689.147-.689.66v.865C-5.979-3.426-5.8-3.279-5.29-3.279Zm4.866,0H.44c.513,0,.689-.147.689-.66V-4.8c0-.513-.176-.66-.689-.66H-.424c-.513,0-.689.147-.689.66v.865C-1.113-3.426-.937-3.279-.424-3.279Zm4.881,0h.85c.513,0,.689-.147.689-.66V-4.8c0-.513-.176-.66-.689-.66h-.85c-.528,0-.689.147-.689.66v.865C3.767-3.426,3.929-3.279,4.456-3.279Zm4.866,0h.865c.513,0,.674-.147.674-.66V-4.8c0-.513-.161-.66-.674-.66H9.322c-.513,0-.689.147-.689.66v.865C8.633-3.426,8.809-3.279,9.322-3.279ZM-5.29,1.528h.865c.513,0,.674-.161.674-.674V-.011c0-.513-.161-.66-.674-.66H-5.29c-.513,0-.689.147-.689.66V.854C-5.979,1.367-5.8,1.528-5.29,1.528Zm4.866,0H.44c.513,0,.689-.161.689-.674V-.011c0-.513-.176-.66-.689-.66H-.424c-.513,0-.689.147-.689.66V.854C-1.113,1.367-.937,1.528-.424,1.528Zm4.881,0h.85c.513,0,.689-.161.689-.674V-.011c0-.513-.176-.66-.689-.66h-.85c-.528,0-.689.147-.689.66V.854C3.767,1.367,3.929,1.528,4.456,1.528Z'
      transform='translate(12.164 20.032)'
      fill='#e6eef5'
    />
    <path
      id='Контур_13766'
      data-name='Контур 13766'
      d='M-.424-8.072H.44c.513,0,.689-.147.689-.66V-9.6c0-.513-.176-.674-.689-.674H-.424c-.513,0-.689.161-.689.674v.865C-1.113-8.219-.937-8.072-.424-8.072Zm4.881,0h.85c.513,0,.689-.147.689-.66V-9.6c0-.513-.176-.674-.689-.674h-.85c-.528,0-.689.161-.689.674v.865C3.767-8.219,3.929-8.072,4.456-8.072Zm4.866,0h.865c.513,0,.674-.147.674-.66V-9.6c0-.513-.161-.674-.674-.674H9.322c-.513,0-.689.161-.689.674v.865C8.633-8.219,8.809-8.072,9.322-8.072ZM-5.29-3.279h.865c.513,0,.674-.147.674-.66V-4.8c0-.513-.161-.66-.674-.66H-5.29c-.513,0-.689.147-.689.66v.865C-5.979-3.426-5.8-3.279-5.29-3.279Zm4.866,0H.44c.513,0,.689-.147.689-.66V-4.8c0-.513-.176-.66-.689-.66H-.424c-.513,0-.689.147-.689.66v.865C-1.113-3.426-.937-3.279-.424-3.279Zm4.881,0h.85c.513,0,.689-.147.689-.66V-4.8c0-.513-.176-.66-.689-.66h-.85c-.528,0-.689.147-.689.66v.865C3.767-3.426,3.929-3.279,4.456-3.279Zm4.866,0h.865c.513,0,.674-.147.674-.66V-4.8c0-.513-.161-.66-.674-.66H9.322c-.513,0-.689.147-.689.66v.865C8.633-3.426,8.809-3.279,9.322-3.279ZM-5.29,1.528h.865c.513,0,.674-.161.674-.674V-.011c0-.513-.161-.66-.674-.66H-5.29c-.513,0-.689.147-.689.66V.854C-5.979,1.367-5.8,1.528-5.29,1.528Zm4.866,0H.44c.513,0,.689-.161.689-.674V-.011c0-.513-.176-.66-.689-.66H-.424c-.513,0-.689.147-.689.66V.854C-1.113,1.367-.937,1.528-.424,1.528Zm4.881,0h.85c.513,0,.689-.161.689-.674V-.011c0-.513-.176-.66-.689-.66h-.85c-.528,0-.689.147-.689.66V.854C3.767,1.367,3.929,1.528,4.456,1.528Z'
      transform='translate(12.164 20.032)'
      fill='#fff'
    />
    <g
      id='Эллипс_7'
      data-name='Эллипс 7'
      transform='translate(18.207 12.045)'
      fill='#e6eef5'
      stroke='#fff'
      strokeWidth='1'
    >
      <ellipse
        cx='10.806'
        cy='10.806'
        rx='10.806'
        ry='10.806'
        stroke='none'
      />
      <ellipse
        cx='10.806'
        cy='10.806'
        rx='10.306'
        ry='10.306'
        fill='none'
      />
    </g>
    <path
      id='Контур_13723'
      data-name='Контур 13723'
      d='M1062-1812.111v4.288l2.781,2.781'
      transform='translate(-1032.987 1831.226)'
      fill='none'
      stroke='#feffff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </SvgIcon>
);
