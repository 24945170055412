import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const CogwheelIcon = ({ className }) => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <SvgIcon viewBox='0 0 16.632 15.736' className={className}>
    <path
      id='ic_Settings'
      d='M16.619,6.584a.345.345,0,0,0-.227-.128L14.41,6.169a6,6,0,0,0-.444-1q.195-.256.585-.722t.552-.681a.381.381,0,0,0,.087-.236.319.319,0,0,0-.076-.225,14.376,14.376,0,0,0-1.787-1.742.431.431,0,0,0-.27-.1.363.363,0,0,0-.26.092l-1.537,1.1a6.916,6.916,0,0,0-.975-.379L9.981.381A.3.3,0,0,0,9.857.16.424.424,0,0,0,9.592.073h-2.4A.361.361,0,0,0,6.8.36a15.916,15.916,0,0,0-.314,1.905,6.4,6.4,0,0,0-.985.389L4,1.559a.477.477,0,0,0-.282-.1q-.238,0-1.023.732a10.418,10.418,0,0,0-1.067,1.1.418.418,0,0,0-.1.236.374.374,0,0,0,.108.246Q2.369,4.6,2.8,5.186a4.912,4.912,0,0,0-.422.942L.366,6.415a.328.328,0,0,0-.206.133.381.381,0,0,0-.087.236V9.058A.373.373,0,0,0,.16,9.3a.345.345,0,0,0,.227.128L2.369,9.7a4.911,4.911,0,0,0,.444,1.014q-.195.256-.585.722t-.552.681a.381.381,0,0,0-.087.236.385.385,0,0,0,.076.236,13.875,13.875,0,0,0,1.787,1.721.383.383,0,0,0,.271.113.4.4,0,0,0,.271-.092l1.527-1.1a6.931,6.931,0,0,0,.975.379L6.8,15.5a.3.3,0,0,0,.125.22.423.423,0,0,0,.265.087h2.4a.361.361,0,0,0,.39-.287,15.965,15.965,0,0,0,.314-1.905,6.379,6.379,0,0,0,.985-.389l1.494,1.107a.55.55,0,0,0,.282.092q.238,0,1.018-.727a10.587,10.587,0,0,0,1.072-1.107.332.332,0,0,0,.1-.236.384.384,0,0,0-.108-.256q-.78-.9-1.159-1.414a6.389,6.389,0,0,0,.422-.932l2-.287a.332.332,0,0,0,.217-.133.382.382,0,0,0,.086-.236V6.825a.372.372,0,0,0-.087-.241ZM10.35,9.8a2.749,2.749,0,0,1-1.96.768A2.749,2.749,0,0,1,6.43,9.8a2.523,2.523,0,0,1,0-3.709,2.75,2.75,0,0,1,1.96-.768,2.749,2.749,0,0,1,1.96.768,2.523,2.523,0,0,1,0,3.709Z'
      transform='translate(-0.073 -0.073)'
      fill='#808f94'
      fillRule='evenodd'
    />
  </SvgIcon>
);

export default CogwheelIcon;
