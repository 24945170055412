/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 40.806 41.357' className={className}>
    <path id='Контур_157' data-name='Контур 157' d='M1142.872,542.205h-1.5a16.839,16.839,0,1,1-4.932-11.906,16.778,16.778,0,0,1,4.932,11.906h3a19.838,19.838,0,1,0-19.838,19.838,19.838,19.838,0,0,0,19.838-19.838Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_158' data-name='Контур 158' d='M1126.034,560.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_159' data-name='Контур 159' d='M1126.034,527.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_160' data-name='Контур 160' d='M1142.387,540.705h-2.706a1.5,1.5,0,0,0,0,3h2.706a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_161' data-name='Контур 161' d='M1109.387,540.705h-2.706a1.5,1.5,0,0,0,0,3h2.706a1.5,1.5,0,0,0,0-3Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_162' data-name='Контур 162' d='M1126.034,542.055V531.417a1.5,1.5,0,0,0-3,0v10.638A1.5,1.5,0,0,0,1126.034,542.055Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_163' data-name='Контур 163' d='M1124.534,543.712h8.2a1.5,1.5,0,0,0,0-3h-8.2A1.5,1.5,0,0,0,1124.534,543.712Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_164' data-name='Контур 164' d='M1128.349,541.912a3.612,3.612,0,1,1-3.611-3.611A3.612,3.612,0,0,1,1128.349,541.912Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <circle id='Эллипс_20' data-name='Эллипс 20' cx='6.26' cy='6.26' r='6.26' transform='translate(0 4.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_165' data-name='Контур 165' d='M1118,529.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.736,4.736,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_166' data-name='Контур 166' d='M1113.431,529h-4.712a.691.691,0,0,0,0,1.382h4.712A.691.691,0,0,0,1113.431,529Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_167' data-name='Контур 167' d='M1111.468,527.37l2.813,2.813v-.978l-2.813,2.813a.692.692,0,0,0,.978.978l2.813-2.813a.7.7,0,0,0,0-.978l-2.813-2.813A.692.692,0,0,0,1111.468,527.37Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <circle id='Эллипс_21' data-name='Эллипс 21' cx='6.26' cy='6.26' r='6.26' transform='translate(0 29.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_168' data-name='Контур 168' d='M1118,554.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.736,4.736,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
    <path id='Контур_169' data-name='Контур 169' d='M1111.811,552.777l.837-.926a.192.192,0,0,1,.334.138l-.063,1.247a.192.192,0,0,0,.2.2l1.247-.064a.192.192,0,0,1,.138.334l-.926.837v.285l.926.837a.192.192,0,0,1-.138.334l-1.247-.064a.192.192,0,0,0-.2.2l.063,1.247a.192.192,0,0,1-.334.138l-.837-.926h-.285l-.837.926a.192.192,0,0,1-.334-.138l.064-1.247a.193.193,0,0,0-.2-.2l-1.247.064a.192.192,0,0,1-.138-.334l.926-.837v-.285l-.926-.837a.192.192,0,0,1,.138-.334l1.247.064a.193.193,0,0,0,.2-.2l-.064-1.247a.192.192,0,0,1,.334-.138l.837.926Z' transform='translate(-1103.566 -521.516)' fill='#faac2e' />
  </SvgIcon>
);
