/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 40.806 41.357' className={className}>
    <path id='Контур_15' data-name='Контур 15' d='M1196.2,742.205h-1.5a19.838,19.838,0,1,0,19.838-19.838,19.838,19.838,0,0,0-19.838,19.838h3a16.839,16.839,0,1,1,4.932,11.907,16.783,16.783,0,0,1-4.932-11.907Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_16' data-name='Контур 16' d='M1216.034,760.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_17' data-name='Контур 17' d='M1216.034,727.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_18' data-name='Контур 18' d='M1196.681,743.705h2.7a1.5,1.5,0,0,0,0-3h-2.7a1.5,1.5,0,0,0,0,3Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_19' data-name='Контур 19' d='M1229.681,743.705h2.7a1.5,1.5,0,0,0,0-3h-2.7a1.5,1.5,0,0,0,0,3Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_20' data-name='Контур 20' d='M1216.034,742.055V731.417a1.5,1.5,0,0,0-3,0v10.638A1.5,1.5,0,0,0,1216.034,742.055Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_21' data-name='Контур 21' d='M1214.534,740.712h-8.2a1.5,1.5,0,0,0,0,3h8.2A1.5,1.5,0,0,0,1214.534,740.712Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_22' data-name='Контур 22' d='M1210.719,741.912a3.611,3.611,0,1,0,3.611-3.611A3.611,3.611,0,0,0,1210.719,741.912Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <circle id='Эллипс_5' data-name='Эллипс 5' cx='6.26' cy='6.26' r='6.26' transform='translate(24.449 4.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_23' data-name='Контур 23' d='M1233.583,729.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.74,4.74,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_24' data-name='Контур 24' d='M1229.01,729H1224.3a.691.691,0,0,0,0,1.382h4.712A.691.691,0,0,0,1229.01,729Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_25' data-name='Контур 25' d='M1227.047,727.37l2.813,2.813v-.978l-2.813,2.813a.692.692,0,0,0,.978.978l2.813-2.813a.7.7,0,0,0,0-.978l-2.813-2.813A.692.692,0,0,0,1227.047,727.37Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <circle id='Эллипс_6' data-name='Эллипс 6' cx='6.26' cy='6.26' r='6.26' transform='translate(24.449 29.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_26' data-name='Контур 26' d='M1233.583,754.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.74,4.74,0,0,1,1.394,3.366h3a7.76,7.76,0,1,0-7.76,7.759,7.759,7.759,0,0,0,7.76-7.759Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_27' data-name='Контур 27' d='M1228.371,752.539l-3.332,3.332a.692.692,0,0,0,.978.978q1.665-1.667,3.332-3.332A.692.692,0,0,0,1228.371,752.539Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
    <path id='Контур_28' data-name='Контур 28' d='M1229.349,755.871q-1.666-1.666-3.332-3.332a.692.692,0,0,0-.978.978l3.332,3.332A.692.692,0,0,0,1229.349,755.871Z' transform='translate(-1194.696 -721.516)' fill='#faac2e' />
  </SvgIcon>
);
