/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function HelpIcon() {
  return (
    <SvgIcon viewBox='0 0 24.902 24.902' data-name='help-icon'>
      <path
        id='Контур_12983'
        data-name='Контур 12983'
        d='M0,3.638A12.535,12.535,0,0,0,12.451-8.813,12.554,12.554,0,0,0-.012-21.265,12.541,12.541,0,0,0-12.451-8.813,12.543,12.543,0,0,0,0,3.638ZM0,1.563A10.326,10.326,0,0,1-10.364-8.813,10.323,10.323,0,0,1-.012-19.189,10.362,10.362,0,0,1,10.376-8.813,10.329,10.329,0,0,1,0,1.563ZM-.256-6.287a.883.883,0,0,0,.989-.867V-7.3c0-.684.4-1.111,1.245-1.672C3.162-9.753,4-10.474,4-11.926,4-13.965,2.2-15.051.134-15.051c-2.1,0-3.479.989-3.821,2.112a2.124,2.124,0,0,0-.1.61.825.825,0,0,0,.83.842c.7,0,.806-.378,1.2-.83A2.014,2.014,0,0,1,.049-13.391c1.111,0,1.843.623,1.843,1.563,0,.83-.525,1.233-1.587,1.965A2.834,2.834,0,0,0-1.221-7.41v.159A.859.859,0,0,0-.256-6.287Zm-.024,3.65a1.3,1.3,0,0,0,1.318-1.27A1.287,1.287,0,0,0-.281-5.176,1.3,1.3,0,0,0-1.6-3.906,1.311,1.311,0,0,0-.281-2.637Z'
        transform='translate(12.451 21.265)'
        fill='#808f94'
      />
    </SvgIcon>
  );
}
