/* eslint-disable import/prefer-default-export */
export const GET_ORGANISATION_MODULES = 'GET_ORGANISATION_MODULES';
export const POST_SUPPORT_TICKET = 'POST_SUPPORT_TICKET';
export const POST_SUPPORT_TICKET_SUCCESS = 'POST_SUPPORT_TICKET_SUCCESS';
export const POST_SUPPORT_TICKET_ERROR = 'POST_SUPPORT_TICKET_ERROR';
export const DONE_SUPPORT_TICKET = 'DONE_SUPPORT_TICKET';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO_COMPANY';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_ERROR = 'GET_COMPANY_INFO_ERROR';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
