/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function EventsIcon({ fill }) {
  return (
    <SvgIcon viewBox='0 0 20.635 18.701' data-name='events-icon'>
      <path
        id='13751'
        data-name='13751'
        d='M-10.039-1.71H5.149A2.611,2.611,0,0,0,7.872-4.433a2.668,2.668,0,0,0-.365-1.342L-.1-19.029A2.694,2.694,0,0,0-2.44-20.41a2.7,2.7,0,0,0-2.349,1.382L-12.4-5.765a2.633,2.633,0,0,0-.365,1.332A2.611,2.611,0,0,0-10.039-1.71Zm.01-1.549a1.143,1.143,0,0,1-1.135-1.174,1.369,1.369,0,0,1,.128-.592l7.6-13.253a1.134,1.134,0,0,1,1-.553,1.083,1.083,0,0,1,.977.553L6.126-5.015a1.232,1.232,0,0,1,.158.582A1.16,1.16,0,0,1,5.129-3.259Zm7.589-5.1a.7.7,0,0,0,.75-.789l.148-5.2a.863.863,0,0,0-.908-.878.842.842,0,0,0-.9.868L-3.22-9.15A.719.719,0,0,0-2.44-8.361Zm0,3.207A1.06,1.06,0,0,0-1.374-6.18,1.047,1.047,0,0,0-2.44-7.206,1.047,1.047,0,0,0-3.506-6.18,1.06,1.06,0,0,0-2.44-5.154Z'
        transform='translate(12.762 20.41)'
        fill={fill || '#808f94'}
      />
    </SvgIcon>
  );
}
