/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function SettingsIcon() {
  return (
    <SvgIcon viewBox='0 0 24.597 20.789' data-name='settings-icon'>
      <path
        id='Контур_13423'
        data-name='Контур 13423'
        d='M4.376-13.318a2.954,2.954,0,0,0,2.759-1.941h4.224a.975.975,0,0,0,.94-.989.964.964,0,0,0-.94-.977H7.135a2.947,2.947,0,0,0-2.759-1.965,2.962,2.962,0,0,0-2.771,1.965H-11.322a.964.964,0,0,0-.977.977.974.974,0,0,0,.977.989H1.617A2.941,2.941,0,0,0,4.376-13.318Zm0-1.5a1.417,1.417,0,0,1-1.428-1.44,1.407,1.407,0,0,1,1.428-1.428A1.407,1.407,0,0,1,5.8-16.26,1.417,1.417,0,0,1,4.376-14.819ZM-11.371-9.778a.965.965,0,0,0-.928.989.954.954,0,0,0,.928.977H-7A2.93,2.93,0,0,0-4.242-5.847,2.958,2.958,0,0,0-1.471-7.812H11.31a.974.974,0,0,0,.989-.977.985.985,0,0,0-.989-.989H-1.483a2.941,2.941,0,0,0-2.759-1.941A2.941,2.941,0,0,0-7-9.778Zm7.129,2.417A1.422,1.422,0,0,1-5.67-8.789a1.415,1.415,0,0,1,1.428-1.428A1.407,1.407,0,0,1-2.814-8.789,1.415,1.415,0,0,1-4.242-7.361ZM4.376,1.6A2.936,2.936,0,0,0,7.135-.354h4.224a.964.964,0,0,0,.94-.977.975.975,0,0,0-.94-.989H7.135A2.925,2.925,0,0,0,4.376-4.26,2.925,2.925,0,0,0,1.617-2.319H-11.322a.974.974,0,0,0-.977.989.964.964,0,0,0,.977.977H1.605A2.964,2.964,0,0,0,4.376,1.6Zm0-1.5A1.415,1.415,0,0,1,2.948-1.331,1.4,1.4,0,0,1,4.376-2.759,1.4,1.4,0,0,1,5.8-1.331,1.415,1.415,0,0,1,4.376.1Z'
        transform='translate(12.299 19.189)'
        fill='#808f94'
      />
    </SvgIcon>
  );
}
