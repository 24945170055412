import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const SearchMapIcon = ({ className }) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" className={className}>
    <path d="M16.2856 17.1472L11.0516 11.9132C10.635 12.2572 10.1558 12.5265 9.61412 12.7209C9.07246 12.9153 8.51209 13.0126 7.93304 13.0126C6.50873 13.0126 5.3033 12.5194 4.31675 11.5331C3.33019 10.5468 2.83691 9.34168 2.83691 7.91775C2.83691 6.49381 3.33007 5.28824 4.31637 4.30106C5.30268 3.3139 6.5078 2.82031 7.93175 2.82031C9.35568 2.82031 10.5612 3.31359 11.5484 4.30015C12.5356 5.2867 13.0292 6.49213 13.0292 7.91644C13.0292 8.51152 12.9293 9.0799 12.7295 9.62156C12.5297 10.1632 12.2631 10.6344 11.9298 11.035L17.1638 16.269L16.2856 17.1472ZM7.93304 11.7626C9.00676 11.7626 9.91622 11.39 10.6614 10.6448C11.4066 9.89962 11.7792 8.99016 11.7792 7.91644C11.7792 6.84272 11.4066 5.93326 10.6614 5.18806C9.91622 4.44287 9.00676 4.07027 7.93304 4.07027C6.85932 4.07027 5.94986 4.44287 5.20466 5.18806C4.45948 5.93326 4.08689 6.84272 4.08689 7.91644C4.08689 8.99016 4.45948 9.89962 5.20466 10.6448C5.94986 11.39 6.85932 11.7626 7.93304 11.7626Z" fill="#333945"/>
  </SvgIcon>
);

export default SearchMapIcon;
