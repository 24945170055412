/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default ({ className }) => (
  <SvgIcon viewBox='0 0 40.806 41.357' className={className}>
    <path id='Контур_51' data-name='Контур 51' d='M1105.485,642.205h-1.5a19.838,19.838,0,1,0,19.838-19.838,19.838,19.838,0,0,0-19.838,19.838h3a16.839,16.839,0,1,1,4.932,11.907,16.778,16.778,0,0,1-4.932-11.907Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_52' data-name='Контур 52' d='M1125.323,660.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_53' data-name='Контур 53' d='M1125.323,627.058v-2.7a1.5,1.5,0,0,0-3,0v2.7a1.5,1.5,0,0,0,3,0Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_54' data-name='Контур 54' d='M1105.971,643.705h2.705a1.5,1.5,0,0,0,0-3h-2.705a1.5,1.5,0,0,0,0,3Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_55' data-name='Контур 55' d='M1138.971,643.705h2.705a1.5,1.5,0,0,0,0-3h-2.705a1.5,1.5,0,0,0,0,3Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_56' data-name='Контур 56' d='M1125.323,642.055V631.417a1.5,1.5,0,0,0-3,0v10.638A1.5,1.5,0,0,0,1125.323,642.055Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_57' data-name='Контур 57' d='M1123.823,640.712h-8.2a1.5,1.5,0,0,0,0,3h8.2A1.5,1.5,0,0,0,1123.823,640.712Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_58' data-name='Контур 58' d='M1120.008,641.912a3.611,3.611,0,1,0,3.611-3.611A3.612,3.612,0,0,0,1120.008,641.912Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <circle id='Эллипс_10' data-name='Эллипс 10' cx='6.26' cy='6.26' r='6.26' transform='translate(24.449 11.566) rotate(-67.5)' fill='#ffeed5' />
    <path id='Контур_59' data-name='Контур 59' d='M1130.353,629.694h-1.5a7.759,7.759,0,1,0,7.76-7.759,7.76,7.76,0,0,0-7.76,7.759h3a4.759,4.759,0,1,1,1.394,3.365,4.745,4.745,0,0,1-1.394-3.365Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_60' data-name='Контур 60' d='M1134.926,630.385h4.712a.691.691,0,0,0,0-1.382h-4.712A.691.691,0,0,0,1134.926,630.385Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_61' data-name='Контур 61' d='M1135.912,626.392l-2.813,2.813a.7.7,0,0,0,0,.978l2.813,2.813a.692.692,0,0,0,.977-.978l-2.813-2.813v.978l2.813-2.813A.692.692,0,0,0,1135.912,626.392Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <circle id='Эллипс_11' data-name='Эллипс 11' cx='6.26' cy='6.26' r='6.26' transform='translate(24.449 29.791) rotate(-22.5)' fill='#ffeed5' />
    <path id='Контур_62' data-name='Контур 62' d='M1142.872,654.694h-1.5a4.759,4.759,0,1,1-1.394-3.366,4.736,4.736,0,0,1,1.394,3.366h3a7.759,7.759,0,1,0-7.759,7.759,7.758,7.758,0,0,0,7.759-7.759Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_63' data-name='Контур 63' d='M1138.382,655.77a2.817,2.817,0,0,1-2.611-3.875l.019-.045-.041.018a2.817,2.817,0,1,0,3.725,3.705l.011-.027-.029.012A2.8,2.8,0,0,1,1138.382,655.77Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
    <path id='Контур_64' data-name='Контур 64' d='M1138.382,655.42a2.5,2.5,0,0,1-2.255-3.477c.134-.322-.275-.5-.514-.4a3.166,3.166,0,1,0,4.175,4.174c.1-.238-.073-.649-.4-.514a2.735,2.735,0,0,1-1.01.212c-.448.03-.451.73,0,.7a3.58,3.58,0,0,0,1.2-.237l-.4-.514a2.47,2.47,0,1,1-3.217-3.217l-.514-.395a3.2,3.2,0,0,0,2.93,4.363C1138.834,656.143,1138.832,655.443,1138.382,655.42Z' transform='translate(-1103.985 -621.516)' fill='#faac2e' />
  </SvgIcon>
);
