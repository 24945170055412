/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TrashIcon({ className }) {
  return (
    <SvgIcon viewBox='0 0 13.175 14.947' className={className}>
      <path
        id='Контур_13567'
        data-name='Контур 13567'
        d='M4.668-1.225h6.388a1.449,1.449,0,0,0,1.035-.352,1.415,1.415,0,0,0,.4-1.014l.427-9.775h.968a.548.548,0,0,0,.4-.16.539.539,0,0,0,.164-.4.522.522,0,0,0-.164-.388.548.548,0,0,0-.4-.16H1.829a.54.54,0,0,0-.391.16.522.522,0,0,0-.164.388.539.539,0,0,0,.164.4.54.54,0,0,0,.391.16H2.8l.427,9.775a1.407,1.407,0,0,0,.406,1.014A1.451,1.451,0,0,0,4.668-1.225ZM5.714-3.231A.451.451,0,0,1,5.4-3.345a.425.425,0,0,1-.132-.313l-.192-7.228a.39.39,0,0,1,.114-.309.45.45,0,0,1,.327-.117.441.441,0,0,1,.309.114.425.425,0,0,1,.132.313l.2,7.228a.39.39,0,0,1-.114.309A.45.45,0,0,1,5.714-3.231Zm2.148,0a.463.463,0,0,1-.331-.117.4.4,0,0,1-.124-.3v-7.235a.406.406,0,0,1,.124-.309.463.463,0,0,1,.331-.117.465.465,0,0,1,.327.117.4.4,0,0,1,.128.309v7.235a.392.392,0,0,1-.128.3A.465.465,0,0,1,7.862-3.231Zm2.141,0a.45.45,0,0,1-.327-.117.412.412,0,0,1-.121-.309l.206-7.228a.425.425,0,0,1,.132-.313.451.451,0,0,1,.317-.114.45.45,0,0,1,.327.117.39.39,0,0,1,.114.309l-.206,7.235a.394.394,0,0,1-.124.306A.451.451,0,0,1,10-3.231ZM4.775-13.248H6.091v-1.181a.5.5,0,0,1,.153-.384.587.587,0,0,1,.416-.142H9.05a.6.6,0,0,1,.42.142.494.494,0,0,1,.157.384v1.181h1.309v-1.245a1.6,1.6,0,0,0-.48-1.231,1.869,1.869,0,0,0-1.327-.448H6.574a1.858,1.858,0,0,0-1.32.448,1.6,1.6,0,0,0-.48,1.231Z'
        transform='translate(-1.274 16.172)'
        fill='rgba(244,30,79,0.95)'
      />
    </SvgIcon>
  );
}
