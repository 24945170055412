/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '2em',
    height: '2em',
  },
}));

export default function DeletePageIcon() {
  const classes = useStyles();
  return (
    <SvgIcon viewBox='0 0 42.865 28.289' className={classes.root}>
      <path
        id='13578'
        data-name='Контур 13578'
        d='M11.646,3.221c3.32,0,5.014-1.627,5.014-4.914V-20.9c0-3.287-1.693-4.947-5.014-4.947H-1.984a6.181,6.181,0,0,0-4.831,1.909l-9.114,9.563a4.332,4.332,0,0,0-1.527,3.038A4.361,4.361,0,0,0-15.929-8.3L-6.8,1.2A6.209,6.209,0,0,0-1.967,3.2ZM7.994-4.333a1.365,1.365,0,0,1-1.013-.415L2.449-9.3-2.067-4.748A1.4,1.4,0,0,1-3.1-4.333,1.463,1.463,0,0,1-4.557-5.794a1.424,1.424,0,0,1,.448-1.029l4.5-4.516-4.5-4.516a1.371,1.371,0,0,1-.448-1.029A1.488,1.488,0,0,1-3.1-18.361a1.371,1.371,0,0,1,1.029.448L2.449-13.4l4.516-4.516a1.424,1.424,0,0,1,1.029-.448,1.492,1.492,0,0,1,1.478,1.478,1.371,1.371,0,0,1-.448,1.029l-4.5,4.516,4.5,4.516a1.424,1.424,0,0,1,.448,1.029A1.467,1.467,0,0,1,7.994-4.333Z'
        transform='translate(17.457 25.849)'
        fill='rgba(226,235,244,0.85)'
      />
    </SvgIcon>
  );
}
